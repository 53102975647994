<template>
    <div class="hidden sm:block">
        <div :class="[alt_styling ? 'bg-black hover:bg-scorelitgray' : 'bg-white hover:bg-gray-100']" class="group overflow-hidden shadow rounded-md h-64 relative cursor-pointer transition duration-300">
            <div class="flex h-full">
                <div class="w-4/12 sm:w-5/12 lg:w-4/12 xl:w-6/12 shape z-10">
                    <img v-if="thumbnail" class="transition duration-300 transform h-full object-cover group-hover:-translate-y-1 group-hover:scale-105" :src="thumbnail" alt="">
                </div>
                <div class="w-8/12 sm:w-7/12 lg:w-8/12 xl:w-6/12 px-10 md:px-6 lg:px-8 py-10">
                    <div v-for="i in info" :key="i.key" class="flex justify-between w-full pb-4 unselectable" unselectable="on">
                        <span class="text-orange font-semibold uppercase text-xs mr-4">
                            {{ i.key }}
                        </span>
                        <span :class="[alt_styling ? 'text-white' : 'text-black']" class="font-medium text-xs truncate ...">
                            {{ i.val }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="bg-scorelitgray text-white absolute inset-x-0 bottom-0 py-3 px-8 transition duration-300 group-hover:bg-scorelitorange">
                <div class="flex items-center float-right cursor-pointer unselectable">
                    <span class="inline-block text-xs pr-4 font-medium">
                        {{ footer }}
                    </span>
                    <span class="inline-block">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:hidden group">
        <img v-if="thumbnail" class="rounded-t-md h-48 w-full transition duration-300 transform md:h-full object-cover group-hover:-translate-y-1" :src="thumbnail" alt="">
        <div :class="[alt_styling ? 'bg-black hover:bg-scorelitgray' : 'bg-white hover:bg-gray-100']" class="shadow cursor-pointer transition duration-300">
            <div class="pt-4 pb-0 px-6">
                <div v-for="i in info" :key="i.key" class="flex justify-between w-full pb-4 unselectable" unselectable="on">
                    <span class="text-orange font-semibold uppercase text-xs mr-4">
                        {{ i.key }}
                    </span>
                    <span :class="[alt_styling ? 'text-white' : 'text-black']" class="font-medium text-xs truncate ...">
                        {{ i.val }}
                    </span>
                </div>
            </div>
        </div>
        <div class="h-10 rounded-b-md bg-scorelitgray text-white py-3 px-6 transition duration-300 group-hover:bg-scorelitorange">
            <div class="flex items-center float-right cursor-pointer unselectable">
                <span class="inline-block text-xs pr-4 font-medium">
                    {{ footer }}
                </span>
                <span class="inline-block">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "@/i18n";
const $t = i18n.global.t;
import { dateToHumanReadable } from '@/helper/javascript/HumanReadableDate';

export default {
    props: {
        review: Object,
        match: Object,
        alt_styling: Boolean,
        footer: {
            type: String,
            default: () => $t('dashboard.item.footer')
        }
    },
    name: "ReviewItem",
    computed:{
        thumbnail: function (){
            return (this.match ?? this.review.match).video.thumbnail;
        },
        info: function (){
            if(this.match) {
                return [
                    {
                        key: $t('dashboard.item.match'),
                        val: this.match.video.title
                    },
                    {
                        key: $t('dashboard.item.created_at'),
                        val: dateToHumanReadable(this.match.created_at)
                    },
                ];
            }

            return [
                {
                    key: $t('dashboard.item.match'),
                    val: this.review.match.video.title
                },
                {
                    key: $t('dashboard.item.trainee'),
                    val: this.review.trainee?.name ?? this.review.team?.name
                },
                {
                    key: $t('dashboard.item.product'),
                    val: this.review.product.name
                },
                {
                    key: $t('dashboard.item.created_at'),
                    val: dateToHumanReadable(this.review.created_at)
                },
            ];
        },
    }
}
</script>

<style scoped>
    .shape {
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    }
</style>
