<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <div class="flex items-center mb-4 mx-4 sm:mx-0">
                    <h2 class="text-white uppercase text-xl font-extrabold">
                        <i18n-t keypath="dashboard.in_progress.title" />
                    </h2>
                    <div class="numberCircle h-6 w-6 ml-3 flex justify-center items-center">
                        <span class="text-white text-sm font-medium">{{ reviewItemsInProgress.length }}</span>
                    </div>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 mx-4 sm:mx-0">
                    <template v-if="hasReviewInProgress">
                        <div v-for="review in reviewItemsInProgress" :key="review.id">
                            <router-link :to="{name: 'review', params: {id: review.id}}" unselectable="on">
                                <review-item :review="review" />
                            </router-link>
                        </div>
                    </template>
                    <div v-else class="text-white text-sm font-medium">
                        <i18n-t keypath="dashboard.in_progress.empty" />
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-gradient-to-b from-gradient-scorelitgray to-transparent pt-10 mt-10" >
            <div class="container mx-auto">
                <div class="mx-4 sm:mx-8">
                    <div class="flex items-center mb-4 mx-4 sm:mx-0">
                        <h2 class="text-white uppercase text-xl font-extrabold">
                            <i18n-t keypath="dashboard.new.title" />
                        </h2>
                        <div class="numberCircle h-6 w-6 ml-3 flex justify-center items-center">
                            <span class="text-white text-sm font-medium">{{ reviewItemsNew.length }}</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 mx-4 sm:mx-0">
                        <template v-if="hasReviewNew">
                            <div v-for="review in reviewItemsNew" :key="review.id">
                                <router-link :to="{name: 'review', params: {id: review.id}}" unselectable="on">
                                    <review-item :review="review" :alt_styling="true" />
                                </router-link>
                            </div>
                        </template>
                        <div v-else class="text-white text-sm font-medium">
                            <i18n-t keypath="dashboard.new.empty" />
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/wrappers/Dashboard";
import { indexReview } from "@/helper/api/ReviewHelper";
import ReviewItem from "@/components/ReviewItem";
import { status } from '@/helper/models/Review';

export default {
    name: "Dashboard",
    components: {ReviewItem, DashboardWrapper},
    data() {
        return {
            reviewItems: [],
        }
    },
    computed:{
        reviewItemsInProgress(){
            return this.reviewItems.filter(review => status(review) === "in_progress");
        },
        hasReviewInProgress(){
            return this.reviewItemsInProgress && this.reviewItemsInProgress.length !== 0;
        },
        reviewItemsNew(){
            return this.reviewItems.filter(review => status(review) === "new");
        },
        hasReviewNew(){
            return this.reviewItemsNew && this.reviewItemsNew.length !== 0;
        },
    },
    created() {
        this.getReviewItems();
    },
    methods: {
        getReviewItems() {
            indexReview()
                .then(reviews => {
                    this.reviewItems = reviews;
                })
                .catch(err => {
                    console.log(err);
                });
        },
    }
}
</script>

<style scoped>
    /* credit: https://stackoverflow.com/questions/4861224/how-to-use-css-to-surround-a-number-with-a-circle */
    .numberCircle {
        border-radius: 50%;
        background: linear-gradient(to right, #F7941D 0, #EF4136 100%);
    }
</style>